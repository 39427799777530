import React, { useEffect, useState } from 'react'
import "./oddstable.scss"
import odds from "../../database/odds";
import { useDispatch, useSelector } from 'react-redux';
import { addBet } from '../../store/slices/betslipSlice';
import { ServerURL } from '../../Config';


function calculateOdds(data) {

    const fraction = data.split('/')
    return  ((parseFloat(fraction[0], [10]) / parseFloat(fraction[1], [10])) + 1).toFixed(2)
}

const groupedObjects = 
            (array) => array.reduce((acc, curr) => {

                const index = acc.findIndex(obj => obj.marketId === curr.marketId)

                if (index === -1) {
                    acc.push({ marketId: curr.marketId, name: curr.marketName, lists: [curr]})
                } else {
                    acc[index].lists.push(curr)
                }


                return acc
            }, [])


function reference(value) {
    switch (value) {
        case "1":
            return "Home"
        case "2":
            return "Away"
        case "X":
            return "Draw"
        case "1X":
            return "Home or Draw"
        case "X2":
            return "Draw or Away"
        case "12":
            return "Home or Away"
    
        default:
            return value
    }
}

function convertObjectToString(choiceId, choiceOdd, eventId, choiceName, marketName) {
    
    const obj = {

        choiceId,
        choiceOdd,
        eventId,
        choiceName,
        marketName,
    }


    return JSON.stringify(obj)
}

function joint (name, group) {
    if (group === null) {
        return `${name}`
    }
    else {
        return `${name} ${group}`
    }
}

const OddsTable = (props) => {

    const dispatch = useDispatch()

    const [toggle, setToggle] = useState(false);
    const matchId = useSelector(state => state.matchId.matchId);
    const bets = useSelector(state => state.betslip.bets)

	const toggleState = (e) => {
        
        dispatch(addBet(e))
        
        setToggle(!toggle)
	}

    const matchObj = odds.find(obj => obj.eventId === matchId);
    const selectedBet = bets.find(obj => obj.eventId === matchId) || {};
    


    const markets = groupedObjects(matchObj.markets)

    const [matches, setMatches] = useState([]);

    const [premiumMatches, setPremiumMatches] = useState([])

    const getMatches = async () => {
        const url = `${ServerURL}/node/getdata?node=Matches`;
        const options = {
            method: 'GET',
            headers: {
                'client': 'futnance',
                'api_key': "123456",
                'Content-Type': "application/json"
            }
        };

        try {
            const response = await fetch(url, options);
            const result = await response.json();
            console.log(result.payload[0][0]);
            setMatches(result.payload[0][0])
        } catch (error) {
            console.error(error);
        }
    }

    const getPackageMatches = async () => {
        const url = `${ServerURL}/node/getdata?node=PremiumMatches&sort_key=category&sort_value=${props.package}`;
        const options = {
            method: 'GET',
            headers: {
                'client': 'futnance',
                'api_key': "123456",
                'Content-Type': "application/json"
            }
        };

        try {
            const response = await fetch(url, options);
            const result = await response.json();
            console.log("MATCHES", result.payload);
            setPremiumMatches(result.payload)
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        getMatches();
        getPackageMatches();
    }, [])



  return (
    <div className='oddstable' style={{height: 500, marginTop: 30}}>
        <br />
        <h2> Today's  {props.package} odds </h2>
        <br />
        {
            premiumMatches.map((a, index) => {
                var matchDate = new Date(a.match_date);
                var today = new Date();
                if(today > matchDate){
                    return null
                }
                return(
                    <div className="switch-field" key={index}>
                        <div className="switch-title">{ a.league || "No Fixture" }</div>
                        <div>
                            <span style={{color: "purple", fontWeight: "900", fontSize: 24}}>{a.home} <span style={{fontWeight: "800"}}>VS</span> {a.away}</span>
                            <p style={{fontWeight: "900", fontSize: 18}}>Prediction: {a.Prediction}</p>
                            <p style={{fontWeight: "900", fontSize: 18}}>Match Time: {a.match_date} {a.match_time}</p>
                        </div>
                    </div>
                )
            })
        }
        
    {
                        matches.map((a, index) => {
                            if(a.odd === "" && props.sub === "free"){
                                return (
                                    <div className="switch-field" key={index}>
                                    <div className="switch-title">{ a.fixture || "No Fixture" }</div>
                                    <div>
                                        <span style={{color: "purple", fontWeight: "900", fontSize: 24}}>{a.homeTeam} <span style={{fontWeight: "800"}}>VS</span> {a.awayTeam}</span>
                                        <p style={{fontWeight: "900", fontSize: 18}}>Prediction: {a.prediction}</p>
                                        <p style={{fontWeight: "900",  fontSize: 18}}>League: {a.league}</p>
                                        <p style={{fontWeight: "900", fontSize: 18}}>Odd: {a.odd}</p>
                                    </div>
                                </div>
                                )
                            }
                            if(a.odd !== "" && props.sub === "premium"){
                            if(parseFloat(a.odd) < 1.5){
                                return null
                            }
                            return(
                                <div className="switch-field" key={index}>
                                    <div className="switch-title">{ a.fixture || "No Fixture" }</div>
                                    <div>
                                        <span style={{color: "purple", fontWeight: "900", fontSize: 24}}>{a.homeTeam} <span style={{fontWeight: "800"}}>VS</span> {a.awayTeam}</span>
                                        <p style={{fontWeight: "900", fontSize: 18}}>Prediction: {a.prediction}</p>
                                        <p style={{fontWeight: "900",  fontSize: 18}}>League: {a.league}</p>
                                        <p style={{fontWeight: "900", fontSize: 18}}>Odd: {a.odd}</p>
                                    </div>
                                </div>
                                
                            )
                            }
                            return <></>
                        })
                    }
    </div>
  )
}

export default OddsTable


