import { useState } from "react";
import { HTTPPostNoToken } from "../../Services";
import { ServerURL } from "../../Config";

function Register(){

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fullname, setFullname] = useState("");
    const [expiry, setExpiry] = useState(new Date());
    const [country, setCountry] = useState("")
    const handleLogin = () => {
        const payload = {
            email, password, fullname, expiry, country
        }
        HTTPPostNoToken(`${ServerURL}/auth/onboard`, payload)
        .then(data => {
            console.log(data)
            if(data.code == 200){
                localStorage.setItem("user", JSON.stringify(data.userData));
                localStorage.setItem("userid", data.userid);
                window.location.href = "/";
            }else{
                alert(data.errorMessage);
            }
        })
    }
    return(
        <center>
            <h1>
                Create a Free Account
            </h1>
            <p>Fill the form below to get started</p>
            <input
                style={{
                    width: 300,
                    height: 50,
                    marginTop: 20,
                    border: "2px solid #e2e3e5",
                    borderRadius: 10,
                    padding: 10
                }}
                type="text"
                onChange={(e) => {
                    setFullname(e.target.value);
                }}
                placeholder="Fullname"
            />
            <br />

            <input
                style={{
                    width: 300,
                    height: 50,
                    marginTop: 20,
                    border: "2px solid #e2e3e5",
                    borderRadius: 10,
                    padding: 10
                }}
                type="email"
                onChange={(e) => {
                    setEmail(e.target.value);
                }}
                placeholder="Email"
            />
            <br />
            <input
                style={{
                    width: 300,
                    height: 50,
                    marginTop: 20,
                    border: "2px solid #e2e3e5",
                    borderRadius: 10,
                    padding: 10
                }}
                type="password"
                onChange={(e) => {
                    setPassword(e.target.value);
                }}
                placeholder="Password"
            />
            <br />
            <select
                style={{
                    width: 300,
                    height: 50,
                    marginTop: 20,
                    border: "2px solid #e2e3e5",
                    borderRadius: 10,
                    padding: 10
                }}
                onChange={(e) => {
                    setCountry(e.target.value);
                }}
                placeholder="Password"
            >
                <option value="USD">
                    Select Country
                </option>
                <option value="NG">
                    Nigeria
                </option>
                <option value="GH">
                    Ghana
                </option>
                <option value="UG">
                    Uganda
                </option>
                <option value="ZW">
                    Zambia
                </option>
                <option value="KE">
                    Kenya
                </option>
            </select>
            <br /><br />
           
            <button
                onClick={handleLogin}
                style={{
                    width: 300,
                    height: 50,
                    marginTop: 20,
                    border: "2px solid #e2e3e5",
                    borderRadius: 10,
                    padding: 10,
                    color: "#fff",
                    fontWeight: "900",
                    backgroundColor: "#000"
                }}
            >
                Continue
            </button>
            <br />
            <button
                onClick={() => {
                    window.location.href = "./login"
                }}
                style={{
                    width: 300,
                    height: 50,
                    marginTop: 20,
                    border: "2px solid #e2e3e5",
                    borderRadius: 10,
                    padding: 10,
                    color: "#000",
                    fontWeight: "900",
                    backgroundColor: "#fff"
                }}
            >
                Login
            </button>
        </center>
    )
}

export default Register;