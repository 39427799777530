const HTTPGet = async (url) => {
    var res;
    await fetch(url, {
        headers: {
            "Content-Type" : "application/json",
            "client": "futnance",
            "api_key": "123456"
        }
    })
    .then(response => response.json())
    .then(data => {
        res = data;
    })

    return res;
}

const Get = async (url) => {
    var res;
    await fetch(url)
    .then(response => response.json())
    .then(data => {
        res = data;
    })
    return res;
}

const HTTPGetWithToken = async (url, token) => {
    var res;
    await fetch(url, {
        headers: {
            "Content-Type" : "application/json",
            "client": "futnance",
            "api_key": "123456",
            "Authorization": `Bearer ${token}`
        }
    })
    .then(response => response.json())
    .then(data => {
        res = data;
    })

    return res;
}

const HTTPPostNoToken = async (url, body) => {
    var res;
    await fetch(url, {
        headers: {
            "Content-Type" : "application/json",
            "client": "futnance",
            "api_key": "123456"
        },
        body: JSON.stringify(body),
        method: "POST"
    })
    .then(response => response.json())
    .then(data => {
        res = data;
    })

    return res;
}

const HTTPPostWithToken = async (url, body, token) => {
    var res;
    await fetch(url, {
        headers: {
            "Content-Type" : "application/json",
            "client": "futnance",
            "Authorization": `Bearer ${token}`,
            "api_key": "123456"
        },
        body: JSON.stringify(body),
        method: "POST"
    })
    .then(response => response.json())
    .then(data => {
        res = data;
    })

    return res;
}
const HTTPatch = async (url, body) => {
    var res;
    await fetch(url, {
        headers: {
            "Content-Type" : "application/json",
            "client": "futnance",
            "api_key": "123456"
        },
        body: JSON.stringify(body),
        method: "PATCH"
    })
    .then(response => response.json())
    .then(data => {
        res = data;
    })

    return res;
}

export {Get, HTTPGet, HTTPGetWithToken, HTTPPostNoToken, HTTPPostWithToken, HTTPatch}