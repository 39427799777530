"use-client"
import React, { useEffect, useState } from 'react'
import AdCard from '../../components/AdCard/AdCard'
import Card from '../../components/Card/Card'
import LineupCard from '../../components/LineupCard/LineupCard'
import MatchesCard from '../../components/MatchesCard/MatchesCard'
import OddsTable from '../../components/OddsTable/OddsTable'
import "./home.scss"
import PaymentAndBets from '../../components/PaymentAndBets/PaymentAndBets'
import { ServerURL } from '../../Config'

const Home = () => {
 const [winning, setWinning] = useState([])
 const getMatches = async () => {
  const url = `${ServerURL}/node/getdata?node=Winnings`;
  const options = {
      method: 'GET',
      headers: {
          'client': 'futnance',
          'api_key': "123456",
          'Content-Type': "application/json"
      }
  };
  
  try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log(result.payload[0][0]);
      setWinning(result.payload)
  } catch (error) {
      console.error(error);
  }
}
useEffect(() => {
  getMatches();
  
}, [])
  return (
    <div className='home'>
      <div className='greetings'>
        <h3>
          Welcome back
        </h3>
      </div>

      <div className="ad">
        <AdCard />
      </div>
      <a href="https://t.me/futnance_bot"><img src="https://www.betensured.com/images/banners/inplay900x271.jpg" style={{width: "100%"}} />
      </a>

      <div className="todayInfo" style={{marginBottom: 20}}>

      

      <PaymentAndBets />
      <div
        style={{
          background: "#fff",
          padding: 20,
          boxShadow: "11px 5px 11px 5px #e2e3e5",
          borderRadius: 10,
          height: 500,
          overflowY: "auto"
        }}
      >
        <h3>Winning Tickets</h3>
        <br />
        {
          winning.map((obj, index) => {
            return(
              <div key={index}>
                <img src={obj.screenshot} style={{width: "100%", marginBottom: 20}} />
              </div>
            )
          })
        }
      </div>

      </div>

      <div className="todayLiveMatches">
        <MatchesCard />
        <Card />
        <LineupCard />
      </div>


    </div>
  )
}

export default Home