"use-client";
import { Link } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";
import Home from "./pages/Home/Home";
import News from "./pages/News/News";
import Profile from "./pages/Profile/Profile";
import Basketball from "./pages/Basketball/Basketball";
import "./styles/global.scss"
import Premium from "./pages/Premium/Premium";
import Free from "./pages/Free/Free";
import HowToPay from "./pages/HowToPay/HowToPay";
import About from "./pages/About/About";
import FAQ from "./pages/FAQ/FAQ";
import { useLocation } from "react-router-dom";
import Login from "./pages/Auth/Login";
import Logout from "./pages/Auth/Logout";
import Reset from "./pages/Auth/Reset";
import Register from "./pages/Auth/Register";

function App() {
  const [toggleNav, setToggleNav] = useState(false)

  const handleToggleNav = () => {
    setToggleNav(!toggleNav)
  }

  const colorMode = useSelector(state => state.colorMode.mode)
  const location = useLocation();
  useEffect(() => {
    checkAuth()
    
  }, [])

  const checkAuth = () => {
    console.log(location.pathname);
    const getUser = localStorage.getItem("user");
    if(getUser === null){
      if(location.pathname === "/login" || location.pathname === "/reset" || location.pathname === "/register"){
        return null;
      }
      window.location.href = "./login"
    }
  }

  return (
    <div className={`App ${colorMode}`}>
      <Sidebar toggler={toggleNav} closeNav={handleToggleNav} />
      <div className="AppContainer">
        <Navbar toggleNav={handleToggleNav} />
        <div className="main">
          <Routes>
            <Route path="/">
              <Route index element={<Home />}></Route>
              <Route path="/profile" element={<Profile />}></Route>
              <Route path="/news" element={<News />}></Route>
              <Route path="/basketball" element={<News />}></Route>
              <Route path="/premium" element={<Premium />}></Route>
              <Route path="/free" element={<Free />}></Route>
              <Route path="/howtopay" element={<HowToPay />}></Route>
              <Route path="/aboutus" element={<About />}></Route>
              <Route path="/faq" element={<FAQ />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/logout" element={<Logout />}></Route>
              <Route path="/reset" element={<Reset />}></Route>
              <Route path="/register" element={<Register />}></Route>
            </Route>
          </Routes>
        </div>
        <div className="footer">

        <p>
            Contact Us: <Link href="http://linkedin.com/in/obinna-iloeje-15183a202" target="_blank" rel="noopener noreferrer"> LinkedIn </Link>
            <Link href="http://github.com/samsperd" target="_blank" rel="noopener noreferrer"> Github </Link>
            <Link href="http://twitter.com/samsperd" target="_blank" rel="noopener noreferrer"> Twitter </Link>
          </p>
      </div>

      </div>
    </div>
  );
}

export default App;
//          /sport/football/fifa-world-cup/12469077
//   category id:    1468
//      tournament id: 16       
//       season id 41087

