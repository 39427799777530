import React from 'react'

const News = () => {
  return (
    <>
    
    <iframe width="100%" height="600" src="https://rss.app/embed/v1/imageboard/8cvAkhZT04bVvOlQ" frameborder="0" />
    </>
  )
}

export default News