import React, { useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ReUsableCard from '../ReUsableCard/ReUsableCard'
import './betslip.scss'
import { removeBet, updateStake } from '../../store/slices/betslipSlice';
import { Box, Tab } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Close, KeyboardArrowDown, SportsSoccer } from '@mui/icons-material'
import { useEffect } from 'react';
import OddsTable from '../OddsTable/OddsTable';

const BetSlip = () => {

    const dispatch = useDispatch()

    const [loadCode, setLoadCode] = useState('')

    const [value, setValue] = useState('1');

    const [toggleSlip, setToggleSlip] = useState(true)

    const [screenSize, setScreenSize] = useState(null)


    const bets = useSelector(state => state.betslip.bets)

    const totalOdds = useSelector(state => state.betslip.totalOdds)
    
    const totalAmount = useSelector(state => state.betslip.totalAmount)

    const stake = useSelector(state => state.betslip.stake)
    
    useEffect(() => {

        if (bets.length > 0) {
            setValue('2')
            
        }
         else {
            setValue('1')
        }
    
      
    }, [bets.length])

    useEffect(() => {
      
        const handleResize = () => setScreenSize(window.innerWidth)
        window.addEventListener('resize', handleResize)
        handleResize()

        return () => window.removeEventListener('resize', handleResize)

    }, [])

    
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const handleStake = (e) => {

        dispatch(updateStake(e.target.value))
    }

    const handleRemove = (id) => {
        dispatch(removeBet(id))
    }

    const handleToggleSlip = () => {

        
        if (screenSize > 1024) {
            const betslips = document.getElementById("betslips")
            betslips.scrollIntoView({ behavior: 'smooth'})
        } else {
            setToggleSlip(!toggleSlip)
        }
        
    }
    
    
    
    return (
        <>
            <div>
                <ReUsableCard className={'toggleCard'}>
                    <div className="bettingSlip">
                        <div className='goDown' onClick={() => setToggleSlip(!toggleSlip)}>
                            <KeyboardArrowDown className='icon' />
                        </div>
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} className='timtim' aria-label="lab API tabs example">
                                    <Tab label="Football" value="1" />
                                    <Tab label="Basketball" value="2" />
                                    <Tab label="Table Tennis" value="3" />
                                </TabList>
                                </Box>
                               
                                <TabPanel value="2">
                                
                                </TabPanel>
                                <TabPanel value="1">
                                    <div className='lac'>

                                        <OddsTable sub="free" />
                                    </div>
                                </TabPanel>
                            </TabContext>
                        </Box>
                    </div>
                </ReUsableCard>


            </div>
            
            {
                !toggleSlip && (
                    <span className="floatingButton" onClick={handleToggleSlip}>
                        <span className="betCount">
                            { bets.length }
                        </span>
                        <span>
                            BETSLIP
                        </span>
                    </span>

                )
            }
        </>
)
}

export default BetSlip