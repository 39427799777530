const plans = [
    {
        id: 0,
        name: "Combo, 2 and 5 odds and 20 weekend odds",
        UG: {
            weekly: 25000,
            monthly: 45000
        },
        NG: {
            weekly: 2000,
            monthly: 5000
        },
        GH: {
            weekly: 70,
            monthly: 100
        },
        ZM: {
            weekly: 150,
            monthly: 300
        },
        USD: {
            weekly: 5,
            monthly: 20
        }
    },
    {
        id: 1,
        name: "Big Odds - 150 Odds",
        UG: {
            weekly: 60000,
            monthly: 130000
        },
        NG: {
            weekly: 5000,
            monthly: 10000
        },
        GH: {
            weekly: 150,
            monthly: 300
        },
        ZM: {
            weekly: 400,
            monthly: 600
        },
        USD: {
            weekly: 10,
            monthly: 35
        }
    },

]

export {plans}