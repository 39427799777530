import { CircleOutlined, FiberManualRecord, Star, StarOutlineOutlined } from '@mui/icons-material'
import { Divider } from '@mui/material'
import React from 'react'
import "./card.scss"
import data from '../../database/data'
import { useState } from 'react'
import Logo from '../Logo/Logo'
import { useDispatch, useSelector } from 'react-redux'
import odds from "../../database/odds";
import { addBet, removeBet } from '../../store/slices/betslipSlice'
import { useEffect } from 'react'



function calculateOdds(data) {

    const fraction = data.split('/')
    return  ((parseFloat(fraction[0], [10]) / parseFloat(fraction[1], [10])) + 1).toFixed(2)
}

function joint (name, group) {
    if (group === null) {
        return `${name}`
    }
    else {
        return `${name} ${group}`
    }
}
            

function reference(value) {
    switch (value) {
        case "1":
            return "Home"
        case "2":
            return "Away"
        case "X":
            return "Draw"
        case "1X":
            return "Home or Draw"
        case "X2":
            return "Draw or Away"
        case "12":
            return "Home or Away"
    
        default:
            return value
    }
}


function convertObjectToString(choiceId, choiceOdd, eventId, choiceName, marketName) {
    
    const obj = {

        choiceId,
        choiceOdd,
        eventId,
        choiceName,
        marketName,
    }


    return JSON.stringify(obj)
}


const Card = () => {

    const dispatch = useDispatch()
    const [isLive, setIsLive] = useState(true)
    const [starBG, setStarBG] = useState(false)
    const [ftChoice, setFtChoice] = useState('')
    const [isLoading, setIsLoading] = useState(false)


    const matchId = useSelector(state => state.matchId.matchId);
    const bets = useSelector(state => state.betslip.bets)
    
    const watchCost = useSelector(state => state.betslip.totalAmount)
    
    
    const matchObj = data.find(obj => obj.id === matchId);
    const oddsObj = odds.find(obj => obj.eventId === matchId);
    
    const selectedBet = bets.find(obj => obj.eventId === matchId) || {};
    
    useEffect(() => {
      setFtChoice(selectedBet)
    
    }, [watchCost])
    


    const market = oddsObj?.markets[0]

    const c = market?.choices





    const handleChange = (e) => {
        let obj = JSON.parse(e.target.value)
        
        obj["checked"] = e.target.checked

        if (ftChoice === '') {
            setFtChoice(obj)
            
        } else {
            if (parseInt(ftChoice.choiceId) === parseInt(obj.choiceId)) {
                dispatch(removeBet(ftChoice.eventId))
                setFtChoice('')
            } else {
                setFtChoice(obj)
            }

        }
    }

    const handleSumbit = (e) => {
        e.preventDefault();

        
        if (ftChoice === '') {
            return;
        } else {
            setIsLoading(true)
            setTimeout(() => setIsLoading(false), 1000);

            const obj = {
                target: {
                    value: JSON.stringify(ftChoice),
                    checked: ftChoice.checked
                }
            }

            dispatch(addBet(obj))

        }
        
    
    }

    var width = window.innerWidth;
    
    return (
        <form onSubmit={handleSumbit} className='card'>
            <iframe src="https://getintellisoft.com/futnance/livescores" width={ width > 500 ? 500 : 360} height={300} />
        </form> 
    )
}

export default Card