import { useState } from "react";
import { HTTPPostNoToken } from "../../Services";
import { ServerURL } from "../../Config";

function Login(){

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const handleLogin = () => {
        const payload = {
            email, password
        }
        HTTPPostNoToken(`${ServerURL}/auth/Login`, payload)
        .then(data => {
            console.log(data)
            if(data.code == 200){
                localStorage.setItem("user", JSON.stringify(data.snapshot));
                localStorage.setItem("userid", data.userid);
                window.location.href = "/";
            }else{
                alert(data.errorMessage);
            }
        })
    }
    return(
        <center>
            <h1>
                Secure Login
            </h1>
            <p>Enter your email and password</p>

            <input
                style={{
                    width: 300,
                    height: 50,
                    marginTop: 20,
                    border: "2px solid #e2e3e5",
                    borderRadius: 10,
                    padding: 10
                }}
                type="email"
                onChange={(e) => {
                    setEmail(e.target.value);
                }}
                placeholder="Email"
            />
            <br />
            <input
                style={{
                    width: 300,
                    height: 50,
                    marginTop: 20,
                    border: "2px solid #e2e3e5",
                    borderRadius: 10,
                    padding: 10
                }}
                type="password"
                onChange={(e) => {
                    setPassword(e.target.value);
                }}
                placeholder="Password"
            />
            <br /><br />
            <a style={{padding: 5}} href="./reset">Forgot Password?</a>
            <br />
            <button
                onClick={handleLogin}
                style={{
                    width: 300,
                    height: 50,
                    marginTop: 20,
                    border: "2px solid #e2e3e5",
                    borderRadius: 10,
                    padding: 10,
                    color: "#fff",
                    fontWeight: "900",
                    backgroundColor: "#000"
                }}
            >
                Login
            </button>
            <br />
            <button
                onClick={() => {
                    window.location.href = "./register"
                }}
                style={{
                    width: 300,
                    height: 50,
                    marginTop: 20,
                    border: "2px solid #e2e3e5",
                    borderRadius: 10,
                    padding: 10,
                    color: "#000",
                    fontWeight: "900",
                    backgroundColor: "#fff"
                }}
            >
                Create an Account
            </button>
        </center>
    )
}

export default Login;