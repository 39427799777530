import React, { useEffect } from 'react'
import "./navbar.scss"
import face from '../../assets/images/face-5.jpeg'
import { AccountBalance, AccountBox, DarkMode, FiberManualRecord, KeyboardArrowDown, LightMode, Logout, Notifications, Receipt, Savings, SearchOutlined, SortOutlined } from '@mui/icons-material'
import { useState } from 'react'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { lightMode, darkMode } from '../../store/slices/colorMode'

const Navbar = ({ toggleNav }) => {
    const [user, setUser] = useState({fullname: ""});

    const [displayProfile, setDisplayProfile] = useState(false)

    const [displaySearch, setDisplaySearch] = useState(false)
    
    const dispatch = useDispatch()

    const colorMode = useSelector(state => state.colorMode.mode);


    const handleSearch = () => {
        setDisplayProfile(false)

        setDisplaySearch(!displaySearch)
    }

    useEffect(() => {
        checkUser();
    }, [])

    const checkUser = () => {
        const getUser = localStorage.getItem("user");
        if(getUser === null){
            return null
        }
        setUser(JSON.parse(getUser));
    }
  return (
    <div className='navbar'>
        <div className="wrapper">

            <div className="wrapperGreeting">
                <SortOutlined onClick={toggleNav} className='icon' />
                <h3>
                    Welcome
                </h3>
                    {/* <span className="logo">
                <SportsSoccer className='logoBall' />
                FIFA<span className='logoSpan'>Bet</span></span> */}
            </div>




            <div className="personal">
           
                <div className="profile">
                    <span className="iconSpan colorMode">
                        {
                            colorMode === 'light' ?
                            (<LightMode onClick={() => dispatch(darkMode())} className='icon' />)
                            :
                            (<DarkMode onClick={() => dispatch(lightMode())} className='icon' />)
                        }
                        
                    </span>
                    <span className="imgBox" onClick={() => setDisplayProfile(!displayProfile)}>
                    <img src={"https://img.freepik.com/premium-vector/3d-simple-user-icon-isolated_169241-7120.jpg?w=2000"} alt="" className='img' />
                    </span>
                    <span className='dets' onClick={() => setDisplayProfile(!displayProfile)} >
                        <h4>{user.fullname || "No Name"}</h4>
                        <p>{user.email || ""} <KeyboardArrowDown style={{ color: '#aaa'}} fontSize='small' /> </p>
                    </span>
                </div>

                {
                    displayProfile && (
                        <div className="profileCard">
                            <div className="profile">
                
                                <span className="imgBox">
                                <img src={"https://img.freepik.com/premium-vector/3d-simple-user-icon-isolated_169241-7120.jpg?w=2000"} alt="" className='img' />
                                </span>
                                <div className='info'>
                                    <span>User User</span>
                                    <span>Free Subscription</span>
                                </div>
                            </div>

                            <hr />

                            <div className='otherSources'>
                                <Button onClick={() => {
                                    window.location.href = "./profile"
                                }} variant='text' startIcon={<AccountBox />}> Profile </Button>
                                <Button variant='text' onClick={() => {
                                    window.location.href = "./logout"
                                }} startIcon={<Logout />}> Logout </Button>
                            </div>
                        </div>

                    )
                }
            </div>
        </div>
    </div>
  )
}

export default Navbar
