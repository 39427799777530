import { useState } from "react";
import { HTTPPostNoToken } from "../../Services";
import { ServerURL } from "../../Config";

function Reset(){

    const [email, setEmail] = useState("");
    
    const handleLogin = () => {
        const payload = {
            email
        }
        HTTPPostNoToken(`${ServerURL}/auth/reset-password`, payload)
        .then(data => {
            console.log(data)
            if(data.code == 200){
                alert("Reset link sent to your email")
                window.location.href = "./login";
            }else{
                alert(data.message);
            }
        })
    }
    return(
        <center>
            <h1>
                Forgot your password
            </h1>
            <p>Enter your email to reset it</p>

            <input
                style={{
                    width: 300,
                    height: 50,
                    marginTop: 20,
                    border: "2px solid #e2e3e5",
                    borderRadius: 10,
                    padding: 10
                }}
                type="email"
                onChange={(e) => {
                    setEmail(e.target.value);
                }}
                placeholder="Email"
            />
            
            <br />
            <button
                onClick={handleLogin}
                style={{
                    width: 300,
                    height: 50,
                    marginTop: 20,
                    border: "2px solid #e2e3e5",
                    borderRadius: 10,
                    padding: 10,
                    color: "#fff",
                    fontWeight: "900",
                    backgroundColor: "#000"
                }}
            >
                Send Reset Email
            </button>
            <br /><br /><br />
            <a style={{padding: 5}} href="./login">Back to Login</a>
        </center>
    )
}

export default Reset;