import React, { useEffect, useState } from 'react'
import AdCard from '../../components/AdCard/AdCard'
import Card from '../../components/Card/Card'
import LineupCard from '../../components/LineupCard/LineupCard'
import MatchesCard from '../../components/MatchesCard/MatchesCard'
import OddsTable from '../../components/OddsTable/OddsTable'
import "./home.scss"
import PaymentAndBets from '../../components/PaymentAndBets/PaymentAndBets'
import { HTTPGet, HTTPPostWithToken, HTTPatch } from '../../Services'
import { ServerURL } from '../../Config'
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { plans } from '../../database/plans'

const Premium = () => {

  const [user, setUser] = useState({expiry: new Date()})
  const now = new Date();
  const [expiry, setExpiry] = useState(now);
  const [selected, setSelected] = useState(0);
  const [duration, setDuration] = useState("weekly")
  const [country, setCountry] = useState("USD")
  const [package_, setPackage] = useState(null)
  useEffect(() => {
    const getUser = localStorage.getItem("user");

    
    setUser(JSON.parse(getUser));
    const user_ = JSON.parse(getUser);
    setCountry(user_.country);
    getExpiry();
  }, [])
  
  const getExpiry = () => {
    const getUser = localStorage.getItem("userid");

    HTTPGet(`${ServerURL}/node/getValue?ref=users/${getUser}/package`)
    .then(data => {
      setPackage(data.value)

    })
   
    HTTPGet(`${ServerURL}/node/getValue?ref=users/${getUser}/expiry`)
    .then(data => {
      setExpiry(new Date(data.value))
      if(now > new Date(data.value)){
        setPackage("free")
      }
    })
    
    HTTPGet(`${ServerURL}/node/getValue?ref=users/${getUser}/country`)
    .then(data => {
      setCountry(data.value)

    })
  }
  //FLWPUBK_TEST-d55e0f3ebde8616bdf1b4d287620bc47-X
  const config = {
    public_key: 'FLWPUBK-7a3477f958c67fe80645c6105d525753-X',
    tx_ref: Date.now(),
    amount: plans[selected][country][duration],
    currency: country === "NG" ? "NGN" : country === "GH" ? "GHS" : country === "ZW" ? "ZMW" : country === "UG" ? "UGX" : "USD",
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: user.email,
       phone_number: '07090847584',
      name: user.fullname,
    },
    customizations: {
      title: plans[selected].name + " subscription",
      description: 'Payment for items in cart',
      logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  
  return (
    <div className='home'>
      {
        now <= expiry ?
        <>
          <h5>Premium Subscription Valid till: {expiry.toString()}</h5>
          <OddsTable sub="premium" package={package_} />
        </>
        
        :
        <>
          <h2>
            You do not have an active subscription
          </h2>
          <br />
          <h4>Subscribe to premium plan</h4>
          <br />
          <h3>Currency: {country}</h3>
          <br />
          {/* <h3>Premium Plan: {`${country} ${country === "NG" ? "20,000" : country === "GH" ? "300" : country === "ZW" ? "45,000" : country === "UG" ? "50,000" : "50"}`}</h3> */}
          <p>Select a plan</p>
          <select style={{display: "block", marginTop: 10, height: 50, width: 350, padding: 10}} onChange={(e) => {
            setSelected(e.target.value);
          }}>
            <option value="">Select a Plan</option>
            {
              plans.map((obj, index) => {
                
                return(
                  <>
                    
                      <option value={obj.id}>{obj.name}</option>
                    
                  </>
                )
              })
            }
          </select>
          {
            selected === null ?
            <></>
            :
            
            <>
              <p>Select Duration</p>
              <select style={{display: "block", marginTop: 10, height: 50, width: 350, padding: 10}} onChange={(e) => {
                setDuration(e.target.value);
              }}>
                <option value="weekly">Weekly ({country} {plans[selected][country].weekly})</option>
                <option value="monthly">Monthly ({country} {plans[selected][country].monthly})</option>
              </select>
            </>
            
          }
          <button
                onClick={() => {
                  
                  handleFlutterPayment({
                    callback: async (response) => {
                      console.log(response);
                      if(response.status === "completed"){
                        const getUser = localStorage.getItem("userid");
                        var date = new Date(); // Now
                        date.setDate(date.getDate() + 30); // Set now + 30 days as the new date
                        console.log(date);
                        const body = {
                          ref: `users/${getUser}`,
                          data: {
                            expiry: date,
                            activeSub: parseInt(selected),
                            package: selected == 0 ? "combo_2" : "big_odds"
                          }
                        }
                        const resp = await HTTPatch(`${ServerURL}/set/updateDataObject`, body);
                        console.log(resp)
                        if(resp.code == 200){
                          window.location.href = ""
                        }
                      }else{
                        alert("An error occurred. Please retry")
                      }
                      closePaymentModal() // this will close the modal programmatically
                    },
                    onClose: () => {},
                  });
                }}
                style={{
                    width: 300,
                    height: 50,
                    marginTop: 20,
                    border: "2px solid #e2e3e5",
                    borderRadius: 10,
                    padding: 10,
                    color: "#fff",
                    fontWeight: "900",
                    backgroundColor: "#000"
                }}
            >
                Subscribe
          </button>
        </>
      }
      

    </div>
  )
}

export default Premium