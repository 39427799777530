import React from 'react'
import AdCard from '../../components/AdCard/AdCard'
import Card from '../../components/Card/Card'
import LineupCard from '../../components/LineupCard/LineupCard'
import MatchesCard from '../../components/MatchesCard/MatchesCard'
import OddsTable from '../../components/OddsTable/OddsTable'
import "./home.scss"
import PaymentAndBets from '../../components/PaymentAndBets/PaymentAndBets'

const Free = () => {
 

  return (
    <div className='home'>
      
      <OddsTable sub="free" package="free" />

    </div>
  )
}

export default Free