import { useEffect, useState } from "react";

function Logout(){

   useEffect(() => {
    localStorage.removeItem("user");
    window.location.href = "";
   }, [])
    return(
        <center>
            <h1>
                Secure Login
            </h1>
            <p>Enter your email and password</p>

            
        </center>
    )
}

export default Logout;